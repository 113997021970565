import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBOV7ulzB4C-izOUKQpx6_KUPKpCafg_po',
  authDomain: 'carebloom-db281.firebaseapp.com',
  projectId: 'carebloom-db281',
  storageBucket: 'carebloom-db281.appspot.com',
  messagingSenderId: '297228997017',
  appId: '1:297228997017:web:372d9ce16c74322cc78308',
  measurementId: 'G-HEX9MCVHP7',
};

const dev = process.env.VUE_APP_LOCAL === 'true' ? true : false;
const isLocal = process.env.NODE_ENV === 'development';
const useDevAuth = true;
// console.log('process.env.NODE_ENV', process.env.NODE_ENV);
// console.log('dev', dev);

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();
const functions = firebase.functions();
if (dev) {
  console.log('using emulators');
  db.useEmulator('localhost', 8082);
  functions.useEmulator('localhost', 5002);
  if (useDevAuth) {
    auth.useEmulator('http://localhost:9099/');
  }
  storage.useEmulator('localhost', 9199);
  analytics.logEvent('dev_emulators');
}

const usersCollection = db.collection('users');

export { analytics, auth, db, functions, isLocal, storage, usersCollection };

